<template>
  <orderList />
</template>
<script>
import orderList from '@/components/OrderList.vue';
export default {
  components: {
    orderList,
  },
};
</script>
<style lang="scss" scoped></style>
